import request from "@/api/request"



/**
 * 查询课程
 *
 * @author vigour
 * @date 2022-07-22 17:09:36
 */
 export function jcCoursePage (parameter) {
  return request({
    url: '/course/oauth/jcCourse/page',
    method: 'get',
    params: parameter
  })
}

/**
 * 查询课程
 *
 * @author vigour
 * @date 2022-07-22 17:09:36
 */
 export function jcCourseDetail (parameter) {
  return request({
    url: '/course/oauth/jcCourse/detail',
    method: 'get',
    params: parameter
  })
}

/**
 * 查询课程章节
 *
 * @author vigour
 * @date 2022-07-25 09:06:27
 */
 export function jcCourseItemPage (parameter) {
  return request({
    url: '/course/oauth/jcCourseItem/page',
    method: 'get',
    params: parameter
  })
}


/**
 * 查询课程报名
 *
 * @author vigour
 * @date 2022-08-22 10:15:52
 */
export function jcCourseApplyPage (parameter) {
  return request({
    url: '/course/jcCourseApply/page',
    method: 'get',
    params: parameter
  })
}



/**
 * 查询课程报名
 *
 * @author vigour
 * @date 2022-08-22 10:15:52
 */
 export function applyPage (parameter) {
  return request({
    url: '/course/jcCourseApply/applyPage',
    method: 'get',
    params: parameter
  })
}


/**
 * 添加课程报名
 *
 * @author vigour
 * @date 2022-08-22 10:15:52
 */
 export function jcCourseApplyAdd (parameter) {
  return request({
    url: '/course/jcCourseApply/add',
    method: 'post',
    data: parameter
  })
}
// 课程进度条
export function jcCourseItemLogRead (parameter) {
  return request({
    url: '/course/jcCourseItemLog/read',
    method: 'post',
    data: parameter
  })
}

export function jcCourseItemLogGetPlan (parameter) {
  return request({
    url: '/course/jcCourseItemLog/getPlan',
    method: 'get',
    params: parameter
  })
}
