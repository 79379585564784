<!--
 * @Author: chenlin 891635769@qq.com
 * @Date: 2022-08-04 18:26:03
 * @LastEditors: chenlin 891635769@qq.com
 * @LastEditTime: 2022-08-09 12:24:10
 * @FilePath: \_web_portal\src\views\onlineLearning\index.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div class="main pageMain">
    <Title></Title>
    <div class="topBgc">
      <el-carousel height="350px" class="titleCarousel">
        <el-carousel-item
          v-for="(item, index) in rotationChartList"
          :key="index"
        >
          <img v-if="item.type === '0'" :src="item.image" alt="" />
          <img v-if="item.type === '1'" :src="item.linkAddress" alt="" />
        </el-carousel-item>
      </el-carousel>
    </div>
    <div class="search-input" style="margin-top: 15px">
      <el-select class="select" v-model="onlineType" @change="onlineCheck" placeholder="全部">
        <!-- <el-option
          v-for="(items, idx) in selectList"
          :key="idx"
          :label="items.name"
          :value="items.value"
        ></el-option> -->
        <el-option label="全部" value="1"></el-option>
        <el-option label="课程" value="2"></el-option>
        <el-option label="培训" value="3"></el-option>
      </el-select>
      <el-input
        class="input-search"
        v-model="onlineSearchValue"
        :placeholder="
          onlineType == '2'
            ? '请输入你想要查询的课程'
            : onlineType == '3'
            ? '请输入你想要查询的培训'
            : '请输入你想要查询的课程或者培训'
        "
      ></el-input>
      <el-button
        class="btn-search"
        type="primary"
        icon="el-icon-search"
        @click="onlineSearch"
        >搜索</el-button
      >
    </div>
    <div class="search-title">
      <el-tag effect="plain" @click="titleSearch(item)" v-for="(item,index) in titleList" :key="index">{{item}}</el-tag>
    </div>
    <div class="content c">
      <div v-show="onlineType == '1' || onlineType == '2'">
        <el-row :gutter="20">
        <el-col :span="24" class="col-title">
          <div class="title-head"></div>
          <div class="title-content">
            <span>课程信息</span>
          </div>
        </el-col>
      </el-row>

      <el-row
        v-if="courseList.length > 0"
        :gutter="20"
        style="min-height: 960px"
      >
        <el-col :span="6" v-for="(item, index) in courseList" :key="index">
          <div class="grid-content col-box margin30 isFlexBox">
            <div>
              <img
                class="imgs"
                :src="$imgUrl(item.litimg)"
                :onerror="$global.srcImgUrl"
                alt=""
              />
            </div>
            <div>
              <h4>{{ item.title }}</h4>
              <p>{{ item.subTitle }}</p>
            </div>
            <div>发布者：{{item.orgName}}</div>
            <div>
              <div>{{ item.sumApplyNum }}人已报名</div>
              <el-button
                class="button-apply"
                @click="courseDetails(item)"
                round
                type="primary"
                >查看详情</el-button
              >
            </div>
          </div>
        </el-col>
      </el-row>
      <el-empty v-else description="暂无数据"></el-empty>
      <div v-if="courseList.length > 0" class="pagination">
        <el-pagination
          :background="true"
          layout="prev, pager, next"
          :total="total1"
          :page-size="8"
          @current-change="changePagination1"
        >
        </el-pagination>
      </div>
      <el-row>
        <div class="divider-box">
          <span></span>
        </div>
      </el-row>
      </div>

      <div v-show="onlineType == '1' || onlineType == '3'">
        <el-row :gutter="20" style="">
        <el-col :span="24" class="col-title trainInfo">
          <div class="title-head"></div>
          <div class="title-content title-content-p">
            <span>培训课程</span>
          </div>
        </el-col>
      </el-row>

      <el-row
        :gutter="20"
        style="min-height: 960px"
        v-if="trainingList.length > 0"
      >
        <el-col :span="6" v-for="(item, index) in trainingList" :key="index">
          <div class="grid-content col-box margin30 isFlexBox">
            <div>
              <img
                class="imgs"
                :src="$imgUrl(item.litimg)"
                :onerror="$global.srcImgUrl"
                alt=""
              />
            </div>
            <div>
              <h4>{{ item.title }}</h4>
              <p>{{ item.subTitle }}</p>
            </div>
            <div>发布者：{{item.orgName}}</div>
            <div>
              <div>{{ item.applyNum }}人已报名</div>
              <el-button
                class="button-apply"
                @click="registrationDetails(item.id)"
                round
                type="primary"
                >查看详情</el-button
              >
            </div>
          </div>
        </el-col>
      </el-row>
      <el-empty v-else description="暂无数据"></el-empty>
      <div v-if="trainingList.length > 0" class="pagination">
        <el-pagination
          :background="true"
          layout="prev, pager, next"
          :total="total2"
          :page-size="8"
          @current-change="changePagination2"
        >
        </el-pagination>
      </div>
      </div>
    </div>
    <Footer style="margin-top: 100px"></Footer>
  </div>
</template>

<script>
import Title from "@/components/hometitle/hometitle.vue";
import Footer from "@/components/foot/foot.vue";
// import { jobOrgDetail } from '@/api/index/index'
import { jcCoursePage } from "@/api/course/course";
import { jcTrainingPage } from "@/api/training/training";
import { rotationChart } from "@/api/home/home";
export default {
  data() {
    return {
      onlineType: "1",
      onlineSearchValue: "",
      rotationChartList: [], // 轮播图
      // imgList: [
      //   'https://vkceyugu.cdn.bspapp.com/VKCEYUGU-2f27dc90-c521-4ab6-b56d-30ae8f2fdd88/88362054-336c-4582-9343-3491f92f3e08.jpg',
      //   'https://vkceyugu.cdn.bspapp.com/VKCEYUGU-2f27dc90-c521-4ab6-b56d-30ae8f2fdd88/e1bd0192-3763-4334-ac02-6d8dd2797580.jpg',
      //   'https://vkceyugu.cdn.bspapp.com/VKCEYUGU-2f27dc90-c521-4ab6-b56d-30ae8f2fdd88/0a9289df-46e9-47d5-b384-b978595e3f4b.jpg',
      //   'https://vkceyugu.cdn.bspapp.com/VKCEYUGU-2f27dc90-c521-4ab6-b56d-30ae8f2fdd88/59d28851-9d37-4919-90e4-29ba10a80e2d.jpg',
      // ],
      jobOrg: {},
      courseList: [],
      trainingList: [],
      params1: {
        pageNo: 1,
        pageSize: 8,
        auditStatus: 2,
      },
      params2: {
        pageNo: 1,
        pageSize: 8,
        auditStatus: 2,
      },
      total1: 0,
      total2: 0,
      titleList: ['中建三局','湖北上智']
    };
  },
  components: {
    Title,
    Footer,
  },
  created() {
    this.jcCoursePage();
    this.jcTrainingPage();
    // this.getJobOrgDetail()
    this.getRotationChart();
  },
  mounted() {},
  methods: {
    jcCoursePage() {
      jcCoursePage(this.params1).then((res) => {
        if (res.data) {
          this.courseList = res.data.rows;
          this.total1 = res.data.totalRows;
        }
      });
    },

    jcTrainingPage() {
      jcTrainingPage(this.params2).then((res) => {
        if (res.data) {
          this.trainingList = res.data.rows;
          this.total2 = res.data.totalRows;
        }
      });
    },
    changePagination1(cur) {
      this.params1.pageNo = cur;
      this.jcCoursePage();
    },
    changePagination2(cur) {
      this.params2.pageNo = cur;
      this.jcTrainingPage();
    },
    courseDetails(item) {
      this.$router.push({
        path: "/courseDetails",
        query: { id: item.id, sumApplyNum: item.sumApplyNum },
      });
    },
    registrationDetails(id) {
      this.$router.push({
        path: "/registrationDetails",
        query: { id },
      });
    },
    // 轮播图
    getRotationChart() {
      rotationChart({ model: 1 }).then((res) => {
        console.log(res, "轮播图");
        this.rotationChartList = res.data;
      });
    },
    onlineSearch() {
      this.courseList = [];
      this.trainingList = [];
      this.params1.pageNo = 1;
      this.params2.pageNo = 1;
      this.total1 = 0;
      this.total2 = 0;
      if (this.onlineType == "1") {
        this.params1.title = this.onlineSearchValue;
        this.params2.title = this.onlineSearchValue;
        this.jcCoursePage();
        this.jcTrainingPage();
      }else if(this.onlineType == "2"){
          this.params1.title = this.onlineSearchValue;
          this.params2.title = ''
           this.jcCoursePage();
           this.jcTrainingPage();
      }else if(this.onlineType == "3"){
        this.params1.title = ''
          this.params2.title = this.onlineSearchValue;
          this.jcCoursePage();
          this.jcTrainingPage();
      }
    },
    titleSearch(item) {
      this.onlineSearchValue = item
      this.onlineSearch()
    },
    onlineCheck(){
      this.params1.pageNo = 1;
      this.params2.pageNo = 1;
      this.total1 = 0;
      this.total2 = 0;
      this.params1.title = this.onlineSearchValue;
      this.params2.title = this.onlineSearchValue
      this.jcCoursePage();
      this.jcTrainingPage();
    },
    getJobOrgDetail() {
      jobOrgDetail({ id: 1 }).then((res) => {
        this.jobOrg = res.data;
        this.jobOrg.files = this.jobOrg.files.split(",");
      });
    },
  },
  filters: {},
};
</script>

<style scoped lang="scss">
.c {
  width: 1320px;
  margin: 0 auto;
}

p {
  margin: 0;
  padding: 0;
}

.pageMain {
  background-color: #ffffff;
}

.titleCarousel {
  img {
    object-fit: cover;
    height: 100%;
    width: 100%;
  }
}
.dividerCss {
  margin: 40px 0;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0px;
  line-height: 0px;
  color: rgba(153, 153, 153, 1);
}
.topBgc {
  background: #11256a;
  margin-top: 80px;
}

.trainInfo {
  margin: 0px 0px;
}
.margin20 {
  margin-top: 20px;
}
.margin30 {
  margin-top: 30px;
}
.divider-box {
  width: 100%;
  margin: 60px 0;
  display: flex;
  span:nth-child(1) {
    flex: 14;
    height: 0px;
    opacity: 1;
    margin-top: 18px;
    background: rgba(204, 204, 204, 1);
    border-bottom: 1px solid rgba(216, 216, 216, 1);
  }
  span:nth-child(2) {
    flex: 1;
    font-size: 16px;
    font-weight: 400;
    color: rgba(153, 153, 153, 1);
    text-align: right;
  }
}
.isFlexBox {
  display: flex;
  flex-direction: column;
  div:nth-child(1) {
    flex: 6;
    .imgs {
      width: 100%;
      height: 258px;
      border-radius: 4px;
      // background-color: red;
    }
  }
  div:nth-child(2) {
    flex: 2;
    letter-spacing: 1px;
    padding: 0 15px;
    h4 {
      margin: 10px 0;
      display: -webkit-box;
      overflow: hidden;
      text-overflow: ellipsis;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
    }
    p {
      font-size: 12px;
      color: #666666;
      line-height: 20px;
      display: -webkit-box;
      overflow: hidden;
      text-overflow: ellipsis;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
    }
  }
  // div:nth-child(3) {
  //   flex: 2;
  //   letter-spacing: 1px;
  //   padding: 0 15px;
  //   position: relative;
  //   div:nth-child(1) {
  //     position: absolute;
  //     font-size: 12px;
  //     top: 40%;
  //     color: #666666;
  //     left: 15px;
  //   }
  //   .button-apply {
  //     position: absolute;
  //     top: 25%;
  //     letter-spacing: 1px;
  //     right: 15px;
  //     width: 130px;
  //     height: 35px;
  //     line-height: 5px;
  //   }
  // }
  div:nth-child(3) {
    flex: 1;
    padding: 0 15px;
    margin-top: 20px;
    color: #666666;
  }
  div:nth-child(4) {
    flex: 2;
    letter-spacing: 1px;
    padding: 0 15px;
    // position: relative;
    display: flex;
    align-items: center;
    div:nth-child(1) {
      // position: absolute;
      font-size: 12px;
      // top: 40%;
      color: #666666;
      left: 15px;
    }
    .button-apply {
      // position: absolute;
      // top: 25%;
      letter-spacing: 1px;
      right: 15px;
      width: 130px;
      height: 35px;
      line-height: 5px;
    }
  }
}

.closeEllipsis {
  // padding: 20rpx 30rpx;
  // height: 100rpx;
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}

.content {
  background-color: #ffffff;
  width: 76%;
  margin: 0 auto;
  margin-top: 40px;
  // border: 1px solid red;
  // col-title trainInfo el-col el-col-24
  .col-title {
  }

  .title-head {
    background: rgba(1, 31, 140, 1);
    border-radius: 4px;
    width: 10px;
    height: 70px;
    display: inline-block;
  }

  .title-content {
    margin-left: 20px;
    display: inline-block;
    font-family: "Noto Sans SC";
    span:nth-child(1) {
      position: relative;
      top: -6px;
      font-weight: bolder;
      font-size: 40px;
      font-weight: 500;
    }
    span::before {
      position: absolute;
      font-size: 36px;
      font-weight: 500;
      width: 440px;
      height: 53px;
      content: "COURSE INFORMATION";
      color: rgba(51, 51, 51, 1);
      left: 0;
      top: -23px;
      opacity: 0.27;
    }
  }
  .title-content-p {
    span::before {
      position: absolute;
      font-size: 36px;
      width: 460px;
      height: 53px;
      content: "TRAINING INFORMATION";
      color: rgba(51, 51, 51, 1);
      left: 0;
      top: -23px;
      opacity: 0.27;
    }
  }

  .col-box {
    height: 450px;
    // width: 315px;
    background-color: white;
    border-radius: 4px;
    box-shadow: 0 4px 8px 0 #c8c8c8;
  }
}
.pagination {
  text-align: center;
  margin-top: 20px;
}

::v-deep {
  .el-pagination.is-background .btn-prev {
    /*对下一页的按钮样式进行修改*/
    background: #fff;
  }
  .el-pagination.is-background .btn-next {
    /*对上一页的按钮样式进行修改*/
    background: #fff;
  }
  .el-pagination.is-background .el-pager li:not(.disabled) {
    background-color: #fff; // 进行修改未选中背景和字体
    // color: #fff;
  }
  .el-pagination.is-background .el-pager li:not(.disabled).active {
    background-color: rgb(19, 138, 206); // 进行修改选中项背景和字体
    color: rgb(255, 255, 255);
  }
}
.search-input {
  display: flex;
  align-items: center;
  width: 60%;
  margin: 0 auto;
  position: relative;

  .input-search {
    height: 48px;
  }
  .btn-search {
    width: 171px;
    height: 48px;
    font-size: 18px;
    border-radius: 10px;
    margin-left: -20px;
    z-index: 1;
  }
}
.input-search ::v-deep .el-input__inner {
  height: 48px;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  border-right: none;
  padding-left: 88px;
  padding-right: 25px;
}
.input-search ::v-deep .el-input__inner:focus {
  border: 1px solid #0081d6;
}
.select {
  width: 82px;
  position: absolute;
  top: 2px;
  left: 2px;
  z-index: 10;
  border: none;
}
.select ::v-deep .el-input__inner {
  height: 44px;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  border: none;
}

.search-title {
  width: 60%;
  display: flex;
  margin: 0 auto;
  margin-top: 10px;
  .el-tag {
    margin-right: 10px;
    cursor: pointer;
  }
}
</style>
