import request from "@/api/request"



/**
 * 查询培训
 *
 * @author vigour
 * @date 2022-07-19 11:32:58
 */
 export function jcTrainingPage (parameter) {
  return request({
    url: '/course/oauth/jcTraining/page',
    // url: '/course/jcTrainingOrg/Page',
    method: 'get',
    params: parameter
  })
}

/**
 * 查询培训
 *
 * @author vigour
 * @date 2022-07-19 11:32:58
 */
 export function jcTrainingDetail (parameter) {
  return request({
    url: '/course/oauth/jcTraining/detail',
    method: 'get',
    params: parameter
  })
}


// 培训报名
export function jcTrainingApplyOrgAdd(data) {
  return request({
    method: 'post',
    url: '/course/oauth/jcTrainingApplyOrg/add',
    data:data
  })
}


// 查询培训是否报名
export function jcTrainingIsApply (parameter) {
  return request({
    url: '/course/oauth/jcTraining/isApply',
    method: 'get',
    params: parameter
  })
}

// 培训报名
 export function jcTrainingApplyAdd (parameter) {
  return request({
    url: '/course/jcTrainingApplyOrg/add',
    method: 'post',
    data: parameter
  })
}

// 查询培训报名
export function trainingApplyPage (parameter) {
  return request({
    url: '/course/jcTrainingOrg/applyPage',
    method: 'get',
    params: parameter
  })
}
